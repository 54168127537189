import { Component, OnInit } from '@angular/core';
import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
// import Swal from 'sweetalert2'


@Component({
  selector: 'app-chatboot-ai',
  templateUrl: './chatboot-ai.component.html',
  styleUrls: ['./chatboot-ai.component.css']
})
export class ChatbootAiComponent implements OnInit {

 

  user: SocialUser;
  loggedIn: boolean;

  constructor(private authService: AuthService) { }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  ngOnInit() {
    if (localStorage.fbToken) {
      this.loggedIn = true;
    }
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      console.log(this.user);
    });
    
  }
//   SignintoFB(){
    
//     Swal.fire({
//       title: 'You did not make post in Facebook',
//       text: "You would be sign in to facebook!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, Sign it!'
//     }).then((result) => {
      
//   })
// }

}
