import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketplace-custom-report',
  templateUrl: './marketplace-custom-report.component.html',
  styleUrls: ['./marketplace-custom-report.component.css']
})
export class MarketplaceCustomReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
