import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import {Renderer2 } from '@angular/core';
declare var $:any;
declare var iziToast:any;
@Component({
selector: 'app-ticketing-system-new',
templateUrl: './ticketing-system-new.component.html',
styleUrls: ['./ticketing-system-new.component.css']
})
export class TicketingSystemNewComponent implements OnInit {

constructor(private serverService: ServerService, private router:Router,private rd: Renderer2) { }
queue_list;
priority;
priority_active;
department_active;
department;
status_active;
status;
access_token;
user_id;
admin_id;
color = ['#00FFFF','#d4b19d','#aad47b','#6c87f5','#d264e3','#e67ab5','#5d1ce8'];
closed=false;
emptyticket=false;

changeColour(i,someVar){
	this.rd.setStyle(someVar,'background-color',this.color[i]);
   }
ngOnInit() {


	this.admin_id = localStorage.getItem('admin_id');
	this.user_id = localStorage.getItem('userId');


	$(document).ready(function(){
   $(".dropdown-title").click(function(){
	   $(".pulldown ").toggleClass("active");
   });
   $(".pulldown a").click(function(){
	   alert($(this).text());
   })
	});
	this.access_token =localStorage.getItem('access_token');
this.my_externaltickets();
this.getuserlist();
this.changeMylayout('card');
}

my_externaltickets(){

	let api_req:any = '{"operation":"getmyExternalTicket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"my_externaltickets","user_type":"2","user_id":"'+this.user_id+'","admin_id":"'+this.admin_id+'"}}';
	this.serverService.sendServer(api_req).subscribe((response:any) => {
	  if(response.status=="true"){
		this.queue_list = response.ticket_options;
		if(this.queue_list==null){
			
			this.emptyticket=true;

		}
		this.priority  = response.priority_options;
		this.department = response.department_options;
		this.status = response.status_options;
		if(response.status_option=='closed'){
			$("#dropdown-toggle").prop("disabled", true);
			this.closed=true;
		}
		console.log(this.priority);
	  } 
	}, 
	(error)=>{
		console.log(error);
	});
  }

  getuserlist(){
	let api_req:any = '{"operation":"agents","moduleType":"agents","api_type":"web","access_token":"'+this.access_token+'","element_data":{"action":"user_list","user_id":"'+this.user_id+'"}}';
	this.serverService.sendServer(api_req).subscribe((response:any) => {
	  if(response.status==true){
		this.my_externaltickets();
		
	  } 
	}, 
	(error)=>{
		console.log(error);
	});
  }

  viewMyTicket(ticket_id){
	ticket_id = btoa(ticket_id);
    
    this.router.navigate(['/ticket-view-thread'], { queryParams: { ticket_id: ticket_id } });

  }

  changeMyPriority(ticket_id, priority){
	let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_priority","priority_id":"'+priority+'","ticket_id":"'+ticket_id+'"}}';
	this.serverService.sendServer(api_req).subscribe((response:any) => {
	  if(response.status==true){
		this.my_externaltickets();
		
	  } 
	}, 
	(error)=>{
		console.log(error);
	});

  }

  changeMyDepartment(ticket_id, department){

	console.log(ticket_id +' '+department)
	let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_department","department_id":"'+department+'","ticket_id":"'+ticket_id+'"}}';
	this.serverService.sendServer(api_req).subscribe((response:any) => {
	  if(response.status==true){
		this.my_externaltickets();
		
	  } 
	}, 
	(error)=>{
		console.log(error);
	});

  }
  changeMyStatus(ticket_id, status){
	let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_status","status_id":"'+status+'","ticket_id":"'+ticket_id+'","user_id":"'+this.user_id+'"}}';
	this.serverService.sendServer(api_req).subscribe((response:any) => {
	  if(response.status==true){
		this.my_externaltickets();
		
	  } 
	}, 
	(error)=>{
		console.log(error);
	});

  }

  slectAll(){
   if($("#selectAllQ").prop("checked")) {
	  $(".emailtickets").prop("checked", true);
  } else {
	  $(".emailtickets").prop("checked", false);
  } 
  }
slectunique(){
	$("#selectAllQ").prop("checked",false)
  }
 
  assignbutton(){
	if($(".emailtickets").prop("checked"))
	{
		this.router.navigate(['/assign-tickets']);
		//alert('selected');
	}
	else{
		iziToast.warning({
			message:"Select at least one Ticket",
			position:"topRight"
		});
		

	}
  }

  changeMylayout(theme){
	  if(theme=='table'){
		  $('#table_view').show();
		  $('#card-ticket-view').hide();
	  }
	  else{
		$('#table_view').hide();
		$('#card-ticket-view').show();
	  }

  }
 
   searchdept() {

    var search_txt = $('#deptsearch').val().toLowerCase();
	// alert(search_txt);

    $("#dept-list .dropdown-item").filter(function () {

        $(this).toggle($(this).text().toLowerCase().indexOf(search_txt.toLowerCase()) !== -1);
    });

}


callFunction(tic){
	$('#ticket_'+tic).unbind('click');
}
}
