import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sp-report',
  templateUrl: './sp-report.component.html',
  styleUrls: ['./sp-report.component.css']
})
export class SpReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
