import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ticket-create-new',
  templateUrl: './ticket-create-new.component.html',
  styleUrls: ['./ticket-create-new.component.css']
})
export class TicketCreateNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
