import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
import Swal from 'sweetalert2'
declare var iziToast:any;

@Component({
  selector: 'app-fax-administration',
  templateUrl: './fax-administration.component.html',
  styleUrls: ['./fax-administration.component.css']
})
export class FaxAdministrationComponent implements OnInit {
  addUserForm:FormGroup;
  updateUserForm:FormGroup;
  addDidForm:FormGroup;
  editDidForm:FormGroup;
  assingDidForm:FormGroup;
  constructor(private serverService : ServerService) { }
  list_users;
  queue_list;
  userTime;
  uadmin_id;
  fu_id;
  omniu_id;
  list_dids;
  did_id;
  omni_id;
  reseller_did_usermgnt = true;
  ngOnInit() {

// Password Validation
$("#add_admin_password").keydown(function (e) {
  
  if(e.which == 32){
          iziToast.warning({
            message: "Sorry, Whitespace not allowed",
            position: 'topRight'
        }); 
        return false;
  }
  return e.which !== 32;
});

$("#user_name").keydown(function (e) {
  
  console.log(e.which); 
  if(e.which == 32){
          iziToast.warning({
            message: "Sorry, Whitespace not allowed",
            position: 'topRight'
        }); 
        return false;
  }
  return e.which !== 32;
});

// $("#update_admin_password").keydown(function (e) {
  
//   if(e.which == 32){
//           iziToast.warning({
//             message: "Sorry, Whitespace not allowed",
//             position: 'topRight'
//         }); 
//         return false;
//   }
//   return e.which !== 32;
// });

 this.addUserForm =new FormGroup({
    "user_name" : new FormControl(null,Validators.required),
    // "user_password" : new FormControl(null),
    "first_name" : new FormControl(null,Validators.required),
    "last_name" : new FormControl(null,Validators.required),
    "phone" : new FormControl(null,Validators.required),
    "email" : new FormControl(null,Validators.required),
    "address" : new FormControl(null,Validators.required),
    "company" : new FormControl(null,Validators.required),
    "daily_limit" : new FormControl(null,Validators.required),
    "monthly_limit" : new FormControl(null,Validators.required)
 });
 this.updateUserForm =new FormGroup({
  "user_name" : new FormControl(null,Validators.required),
  "first_name" : new FormControl(null,Validators.required),
  "last_name" : new FormControl(null,Validators.required),
  "phone" : new FormControl(null,Validators.required),
  "email" : new FormControl(null,Validators.required),
  "address" : new FormControl(null,Validators.required),
  "company" : new FormControl(null,Validators.required),
  "daily_limit" : new FormControl(null,Validators.required),
  "monthly_limit" : new FormControl(null,Validators.required)
});

this.addDidForm=new FormGroup({
  "did_number" : new FormControl(null,Validators.required),
  "did_title" : new FormControl(null,Validators.required)

});
this.editDidForm= new FormGroup({
  "did_number" : new FormControl(null,Validators.required),
  "did_title" : new FormControl(null,Validators.required)

});
this.assingDidForm=new FormGroup({
  "did_number" : new FormControl(null,Validators.required),
// post User ID from dropdown
});
let uadmin_id: any=localStorage.getItem('userId');
this.getusers();
this.getDIDs();
this.get_timezone();
  }



  getusers(){
    let access_token: any=localStorage.getItem('access_token');
    let new_admin_id : any=localStorage.getItem('admin_id');
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"list_fax_users","user_id":"'+new_admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        this.list_users = response.result.data;
        console.log(this.list_users);
        // console.log(response);
      } 
    }, 
    (error)=>{
        console.log(error);
    });
  }
  get_timezone(){
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"getTimezone", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_timezone"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status=="true"){
        this.queue_list = response.timezone_options;
        console.log(this.queue_list);
      } else {
       
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  
  addUser(){

          if(this.addUserForm.value.user_name == '' || this.addUserForm.value.user_name== null){
            iziToast.warning({
                message: "Please Enter Username",
                position: 'topRight'
            });
            return false;
        }
        if( this.addUserForm.value.user_name.indexOf(" ") !== -1 )
        {
          iziToast.warning({
            message: "Username should not contain Whitespace.",
            position: 'topRight'
        });return false;
      }
        if(this.addUserForm.value.first_name == '' || this.addUserForm.value.first_name== null){
          iziToast.warning({
              message: "Please Enter first_name",
              position: 'topRight'
          });
          return false;
      }
      if(this.addUserForm.value.last_name == '' || this.addUserForm.value.last_name== null){
        iziToast.warning({
            message: "Please Enter last_name",
            position: 'topRight'
        });
        return false;
    }

        if(this.addUserForm.value.user_name == '' || this.addUserForm.value.user_name== null){
          iziToast.warning({
              message: "Please Enter Username",
              position: 'topRight'
          });
          return false;
      }  

    
    if(this.addUserForm.value.email == '' || this.addUserForm.value.email== null){
      iziToast.warning({
          message: "You should Enter email_id",
          position: 'topRight'
      });
      return false;
    }

    if(this.addUserForm.value.monthly_limit <= this.addUserForm.value.daily_limit){
      iziToast.warning({
          message: "Monthly limit should be grater than daily limit",
          position: 'topRight'
      });
      return false;
  }  
        let timezone=$('#timezone').val();
        let fax_pass=$('#add_admin_password').val();

        if($('#add_admin_password').val()==''||$('#add_admin_password').val()== undefined){
          iziToast.warning({
            message: "Please Enter Password",
            position: 'topRight'
        });
        return false;
        }
        if( $('#add_admin_password').val().indexOf(" ") !== -1 )
        {
          iziToast.warning({
            message: "Password should not contain Whitespace.",
            position: 'topRight'
        });return false;
      }
        // console.log(timezone);

        
          let agent_req:any = this.addUserForm.value;
          let access_token: any=localStorage.getItem('access_token');
          let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_fax_user","user_name":"'+agent_req.user_name+'","password":"'+fax_pass+'","first_name":"'+agent_req.first_name+'","last_name":"'+agent_req.last_name+'","phone":"'+agent_req.phone+'","email":"'+agent_req.email+'","address":"'+agent_req.address+'","timezone_id":"'+timezone+'","company_name":"'+agent_req.company+'","daily_limit":"'+agent_req.daily_limit+'","monthly_limit":"'+agent_req.monthly_limit+'"}}';
    
          this.serverService.sendServer(api_req).subscribe((response:any) => {
            if(response.result.data == 1){
                  $('#add-user-management').modal('hide');
                  // this.addAadmin.reset();
                  // this.admin_settings();
                  iziToast.success({
                      message: "Fax user created successfully",
                      position: 'topRight'
                  });
                    this.getusers();

            }  else if(response.result.data == 3){
                    iziToast.warning({
                      message: "User credential is already exists",
                      position: 'topRight'
                  });
        
             } else{
                    
              iziToast.warning({
                  message: "User data could not retrive. Please try again",
                  position: 'topRight'
              });
          
        }
          }, 
          (error)=>{
              console.log(error);
          });
        }
    
        editUserData(id){
          // let update_timezone=$('#update_timezone').val();
          let access_token: any=localStorage.getItem('access_token');
          let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_fax_user","user_id": "'+id+'"}}';
        
          this.serverService.sendServer(api_req).subscribe((response:any) => {
            if(response.status == true){
              var agent_data = response.result.data;
              this.updateUserForm.setValue({
                "user_name" :agent_data.user_name,
                // "user_password" : agent_data.fax_user_password,
                // "confirm_user_password" : new FormControl(null,Validators.required),
                "first_name" :agent_data.fax_first_name,
                "last_name" :agent_data.fax_last_name,
                "phone" :  agent_data.phone_number,
                "email" :agent_data.email_id ,
                "address" :agent_data.fax_user_address ,
                "company" : agent_data.company_name,
                // "timezone" : agent_data.timezone,
                "daily_limit" : agent_data.fax_daily_limit,
                "monthly_limit" : agent_data.fax_monthly_limit
             });
              this.fu_id = response.result.data.fax_user_id;
              this.omniu_id = response.result.data.user_id;

               this.userTime=agent_data.timezone_id;
        
             
        
            //  this.userchecked = agent_data.department_users.split(",");
            //  console.log(this.userchecked)
            //  $('#edit_qusnform').modal('show');
            //  this.get_qusn();
            }   else{
                    
              iziToast.warning({
                  message: "Question count not retrive. Please try again",
                  position: 'topRight'
              });
          
        }
          }, 
          (error)=>{
              console.log(error);
          });
        }
        updateUser(id,ou_id){

          if(this.updateUserForm.value.user_name == '' || this.updateUserForm.value.user_name== null){
            iziToast.warning({
                message: "Please Enter Username",
                position: 'topRight'
            });
            return false;
        }
        if(this.updateUserForm.value.first_name == '' || this.updateUserForm.value.first_name== null){
          iziToast.warning({
              message: "Please Enter first_name",
              position: 'topRight'
          });
          return false;
      }
      if(this.updateUserForm.value.last_name == '' || this.updateUserForm.value.last_name== null){
        iziToast.warning({
            message: "Please Enter last_name",
            position: 'topRight'
        });
        return false;
    }

        if(this.updateUserForm.value.user_name == '' || this.updateUserForm.value.user_name== null){
          iziToast.warning({
              message: "Please Enter Username",
              position: 'topRight'
          });
          return false;
      }  


    if(this.updateUserForm.value.email == '' || this.updateUserForm.value.email== null){
      iziToast.warning({
          message: "You should Enter email_id",
          position: 'topRight'
      });
      return false;
    }

    if(this.updateUserForm.value.monthly_limit < this.updateUserForm.value.daily_limit){
      console.log(this.updateUserForm.value.monthly_limit);
      iziToast.warning({
          message: "Monthly limit should be grater than daily limit",
          position: 'topRight'
      });
      return false;
  }  

          let timezone=$('#update_timezone').val();
          let agent_req:any = this.updateUserForm.value;
          let access_token: any=localStorage.getItem('access_token');
          let api_req:any = '{"operation":"agents", "moduleType": "agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"update_fax_user","user_id": "'+ou_id+'","fax_user_id":"'+id+'","first_name":"'+agent_req.first_name+'","last_name":"'+agent_req.last_name+'","phone":"'+agent_req.phone+'","email":"'+agent_req.email+'","timezone_id":"'+timezone+'","company_name":"'+agent_req.company+'","address":"'+agent_req.address+'","daily_limit":"'+agent_req.daily_limit+'","monthly_limit":"'+agent_req.monthly_limit+'"}}';
        
              this.serverService.sendServer(api_req).subscribe((response: any) => {
              if (response.result.data == 1) {
                      $('#update-user-management').modal('hide');
                      iziToast.success({
                          message: "User updated successfully",
                          position: 'topRight'
                      });
                      this.getusers();

                  } else {
                      iziToast.warning({
                          message: "Sorry not updated. Please try again",
                          position: 'topRight'
                      });
                  
              }
        
          },
          (error) => {
               iziToast.error({
                  message: "Sorry, some server issue occur. Please contact admin",
                  position: 'topRight'
              });
              console.log(error);
          });
        }

        deleteUser(f_id,u_id){
    
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.value) {
              let access_token: any=localStorage.getItem('access_token');
            
              let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_fax_user", "user_id": "'+u_id+'","fax_user_id":"'+f_id+'"}}';
            
              this.serverService.sendServer(api_req).subscribe((response:any) => {
        
                console.log(response);
                if(response.result.data==1){
                  iziToast.success({
                    message: "Data deleted successfully",
                    position: 'topRight'
                });
                this.getusers();
                } else {
                  iziToast.warning({
                    message: "Data not deleted, Please try again!",
                    position: 'topRight'
                });
                }
              }, 
              (error)=>{
                  console.log(error);
              });
            
          } 
        })
      }

      getDIDs(){
        let access_token: any=localStorage.getItem('access_token');
        let reseller_value :any =localStorage.getItem('reseller');
        let api_req:any = '{"operation":"fax", "moduleType":"fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"didnumber_list"}}';
        if( reseller_value== '' ||reseller_value==null){
                    
          this.reseller_did_usermgnt= true;
        }
        else
        {
          this.reseller_did_usermgnt= false;
    
        }
        this.serverService.sendServer(api_req).subscribe((response:any) => {
          if(response.status==true){
            this.list_dids = response.result.data;
            // console.log(this.list_reports);
            // console.log(response);
          } 
        }, 
        (error)=>{
            console.log(error);
        });
      }

      addDID(){
        let agent_req:any = this.addDidForm.value;
        console.log(this.addDidForm.value);
            let access_token: any=localStorage.getItem('access_token');
            let api_req:any = '{"operation":"fax", "moduleType": "fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"add_didnumber","didnumber":"'+agent_req.did_number+'","title":"'+agent_req.did_title+'"}}';
                this.serverService.sendServer(api_req).subscribe((response: any) => {
                if (response.result.data == 1) {
                        iziToast.success({
                            message: "DID added successfully",
                            position: 'topRight'
                        });
                        this.getDIDs();
                        $('#did-add').modal('hide');
                    }
                    else {
                      iziToast.warning({
                          message: "Sorry,Please try again",
                          position: 'topRight'
                      });
                  
              }
            },
            (error) => {
                 iziToast.error({
                    message: "Sorry, some server issue occur. Please contact admin",
                    position: 'topRight'
                });
                console.log(error);
            });
          
          }


          editDID(id){
            
              let access_token: any=localStorage.getItem('access_token');
              let api_req:any = '{"operation":"fax", "moduleType": "fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_didnumber","didid": "'+id+'"}}';
            
              this.serverService.sendServer(api_req).subscribe((response:any) => {
                if(response.status == true){
                  var agent_data = response.result.data;
                  this.editDidForm.setValue({
                     'did_number' : agent_data.didnumber,
                     'did_title' : agent_data.title,
                 });
                  this.did_id = response.result.data.did_id;
                  this.omni_id = response.result.data.id;

                 $('#did-edit').modal('show');
                 
                 this.assingDidForm.setValue({
                  'did_number' : agent_data.didnumber,
                     });
                }  
                 else{
                        
                  iziToast.warning({
                      message: "DID's count not retrive. Please try again",
                      position: 'topRight'
                  });
              
            }
              }, 
              (error)=>{
                  console.log(error);
              });
            
          }


          updateDID(id,did_id){

            let agent_req:any = this.editDidForm.value;
            let access_token: any=localStorage.getItem('access_token');
            let api_req:any = '{"operation":"fax", "moduleType": "fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"update_didnumber","id": "'+id+'","didnumber":"'+agent_req.did_number+'","title":"'+agent_req.did_title+'","didid":"'+did_id+'"}}';
          
                this.serverService.sendServer(api_req).subscribe((response: any) => {
                if (response.result.data == 1) {
                         $('#did-edit').modal('hide');
                         this.getDIDs();
                        iziToast.success({
                            message: "DID updated successfully",
                            position: 'topRight'
                        });
                    } else {
                    
                        iziToast.warning({
                            message: "Sorry not updated. Please try again",
                            position: 'topRight'
                        });
                    
                }
          
            },
            (error) => {
                 iziToast.error({
                    message: "Sorry, some server issue occur. Please contact admin",
                    position: 'topRight'
                });
                console.log(error);
            });
          }

          deleteDID(id,did_id){
    
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.value) {
                let access_token: any=localStorage.getItem('access_token');
              
                let api_req:any = '{"operation":"fax", "moduleType":"fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_didnumber", "id": "'+id+'","didid":"'+did_id+'"}}';
              
                this.serverService.sendServer(api_req).subscribe((response:any) => {
          
                  console.log(response);
                  if(response.result.data==1){
                    iziToast.success({
                      message: "DID deleted successfully",
                      position: 'topRight'
                  });
                  this.getDIDs();
                  } else {
                    iziToast.warning({
                      message: "DID not deleted, Please try again!",
                      position: 'topRight'
                  });
                  }
                }, 
                (error)=>{
                    console.log(error);
                });
              
            } 
          })
        }
        editDIDdetails(id){
            
          let access_token: any=localStorage.getItem('access_token');
          let api_req:any = '{"operation":"fax", "moduleType": "fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"edit_didnumber","didid": "'+id+'"}}';
        
          this.serverService.sendServer(api_req).subscribe((response:any) => {
            if(response.status == true){
              var agent_data = response.result.data;
              this.assingDidForm.setValue({
                'did_number' : agent_data.didnumber,
                   });
              this.did_id = response.result.data.did_id;
            }  
             else{         
              iziToast.warning({
                  message: "DID's count not retrive. Please try again",
                  position: 'topRight'
              });
        }
          }, 
          (error)=>{
              console.log(error);
          });
        
      }



  assignDID(did_id){
      var assign_user:any =$('#assign_user_id').val();
            console.log(assign_user);
          
          let access_token: any=localStorage.getItem('access_token');
          let api_req:any = '{"operation":"fax", "moduleType": "fax", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"assign_didnumber","didid":"'+did_id+'","selected_user_id":"'+assign_user+'"}}';
        
              this.serverService.sendServer(api_req).subscribe((response: any) => {
              if (response.result.data == 1) {
                       $('#did-assign').modal('hide');
                      this.getDIDs();
                      iziToast.success({
                          message: "DID Assigned successfully",
                          position: 'topRight'
                      });
                  } else {
                  
                      iziToast.warning({
                          message: "Sorry DID not assigned. Please try again",
                          position: 'topRight'
                      });
                  
              }
        
          },
          (error) => {
               iziToast.error({
                  message: "Sorry, some server issue occur. Please contact admin",
                  position: 'topRight'
              });
              console.log(error);
          });
        }

      }
    

  

