import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-buffer-marketing',
  templateUrl: './buffer-marketing.component.html',
  styleUrls: ['./buffer-marketing.component.css']
})
export class BufferMarketingComponent implements OnInit {

  constructor(private serverService: ServerService) { }

  ngOnInit() {
  }

  dept_settings(){
    let access_token: any=localStorage.getItem('access_token');
    let admini: any=localStorage.getItem('admin_id');
   // var posts = '{"operation":"social_media", "moduleType":"social_media"}';
    //var pages = '[{"page_id":"1212121", "page_name":"test"},{"page_id":"121216756456321", "page_name":"tesweret"},{"page_id":"765432345", "page_name":"te334sweret"}]';
  //  let api_req:any = '{"operation":"social_media", "moduleType":"social_media", "api_type": "web", "element_data":{"action":"store_fb_profile","name":"nname","first_name":"Ffirst_name","last_name":"Llast_name","picture":"$picture","id":"id","posts":'+JSON.stringify(posts)+',"user_id":"64","fb_pages":'+JSON.stringify(pages)+'}}';
 // let api_req:any = '{"operation":"social_media", "moduleType":"social_media", "api_type": "web", "element_data":{"action":"getUserFbDetails","user_id":"64"}}';
 //let api_req:any = '{"operation":"social_media", "moduleType":"social_media", "api_type": "web", "element_data":{"action":"postSocialMediaContents","user_id":"64","page_id":"12345678765","post_content":"post_contentsasdsa","post_for":"fb"}}';
 let api_req:any = '{"operation":"social_media", "moduleType":"social_media", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"fb_login_url"}}';

  this.serverService.sendServer(api_req).subscribe((response:any) => {

      console.log(response)
     
    }, 
    (error)=>{
        console.log(error);
    });
  }
}
